<template>
  <div>
    <div v-for="(beitrag, index) in beiträge" :key="`container${index}`">
        <v-container  v-if="beitrag.layout == 'TextNebenBild'">
            <TextNebenBild
                :key="`TextNebenBild${index}`"
                :text="beitrag.text"
                :titel="beitrag.titel"
                :bildquelle="beitrag.bildquellen[0]"
            :höhe="beitrag.höhe"/>
        </v-container>
        <v-container  v-if="beitrag.layout == 'TextNebenBildReverse'">
            <TextNebenBildReverse 
                :key="`TextNebenBildReverse${index}`"
                :text="beitrag.text"
                :titel="beitrag.titel"
                :bildquelle="beitrag.bildquellen[0]"
                :höhe="beitrag.höhe" />
        </v-container>
        <Textfeld
            v-if="beitrag.layout == 'Textfeld'"
            :key="`Textfeld${index}`"
            :text="beitrag.text"
            :überschrift="beitrag.titel"
            >
        </Textfeld>
        <v-row v-if="beitrag.layout == 'DreiBilder'">
            <v-col cols="1" />
            <v-col cols="10">
            <DreiBilder
                :key="`DreiBilder${index}`"
                :bildquelle1="beitrag.bildquellen[0]"
                :bildquelle2="beitrag.bildquellen[1]"
                :bildquelle3="beitrag.bildquellen[2]"
                :höhe="beitrag.höhe"
                >
            </DreiBilder>
            </v-col>
            <v-col cols="1" />
        </v-row>
        <v-container v-if="beitrag.layout == 'Karussell'">
            <v-carousel 
            height="400"
            >
            <v-carousel-item
                v-for="(item,i) in beitrag.bildquellen"
                :key="i"
                :src="item"
                reverse-transition="scale-transition"
                transition="slide-y-reverse-transition"
            ></v-carousel-item>
            </v-carousel>
        </v-container>
        <v-row v-if="beitrag.layout == 'GridBilder'">
            <v-col cols="2" />
            <v-col cols="8">
                <GridBilder
                    :items="beitrag.bildquellen"
                />
            </v-col>
            <v-col cols="2" />
        </v-row>
    </div>
    <v-row>
        <v-spacer/>
        <v-progress-circular
            v-if="beiträge.length == 0"
            indeterminate
            color="primary"
            size="100"
        ></v-progress-circular>
        <v-spacer/>
    </v-row>
  </div>

</template>

<script>
import TextNebenBild from "./TextNebenBild"
import TextNebenBildReverse from "./TextNebenBildReverse"
import Textfeld from "./Textfeld"
import DreiBilder from "./DreiBilder"
import GridBilder from './GridBilder.vue'
export default {
    props:['excelData'],
    components:{
        TextNebenBild,
        TextNebenBildReverse,
        Textfeld,
        DreiBilder,
        GridBilder,
    },
    computed:{
        beiträge: function(){
            var temp = [];
            for(var zeileNummer=0; zeileNummer < this.excelData.length; zeileNummer++){
                var zeile = this.excelData[zeileNummer];
                temp.push(
                    {
                        layout: zeile[0],
                        titel: zeile[1],
                        text: zeile[2],
                        bildquellen: this.getBildquellen([zeile[3], zeile[4], zeile[5], zeile[6], zeile[7], zeile[8]]),
                        höhe: zeile[9]
                    }
                );
            }
            return temp;
        }
    },
    methods:{
        getBildquellen(quellen){
            var temp = []
            for(var i=0; i<quellen.length; i++){
                if(quellen[i] != undefined && quellen[i] != ''){
                    temp.push(this.getBildUrl(quellen[i]))
                }
            }
            return temp
        },
        getBildUrl(id){
            if(id == '' || id == undefined){
                return '';
            }
            return `https://drive.google.com/uc?id=${id}`;
        }
    }
}
</script>

<style>

</style>